import React from 'react';
import { Button, Header, TextBlock, Footer, Layout } from 'components';

import './index.scss';

const Index = () => (
  <Layout>
    <section className="wrapper">
      <div className="index-page">
        <section className="intro-section">
          <h1 className="intro-section-title">
            This is a critical response register for hand sanitiser for the Australian community
          </h1>
          <Button to="/register-demand">Register Demand</Button>
          <p className="intro-section-sideways-boy">
            Germs Gone. <br />
            Jobs Created.
          </p>
        </section>
        <section className="text-block-wrapper">
          <TextBlock
            tagline="Why"
            title="We have built this site to register current and future demand and connect that demand with supply."
          />
          <TextBlock
            tagline="Who are we"
            title="We are an engaged and dynamic group of supply chain experts, fmcg gurus, business leaders, builders, developers, radio presenters, fathers, mothers, friends, concerned citizens, footy lovers and even a cowboy. "
            content={[
              'We have been brought together by CoVid-19, its economic impact and a social responsiblity to our community, to keep people employed and most importantly to keep our country supplied with critical products like hand sanitiser, antibacterial wipes and masks. ',
              'Two of our founders have built a technology platform that configures personal care supply chains rapidly. It has allowed us to get ahead of the curve in terms of production of these products and give our country a shot at meeting the demand of hospitals, aged care facilties, schools, businesses and the community.',
            ]}
          />
          <TextBlock
            tagline="Register demand"
            title="We are currently building a predictive model to get in front of the demand and do our best to ensure hospitals, aged care facilities, and the broader community have what they need when they need it"
            buttonText="Register Demand"
            buttonTo="/register-demand"
          />
        </section>
      </div>
    </section>
  </Layout>
);

export default Index;
